.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #24774a;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    #308f59,
    #24774a,
    #195c38,
    #0a3b24
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #308f59, #24774a, #195c38, #0a3b24);
}

.gradient-form {
  height: calc(100vh - 152px) !important;
}

.code-item {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.code-container {
  padding: 16px;
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
